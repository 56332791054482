import React from "react"
import { graphql } from "gatsby"
import { useTranslation, Trans } from "gatsby-plugin-react-i18next"
import styled from "styled-components"
import { Container, Row, Col } from "react-bootstrap"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

import { APREMIA_PHONE, APREMIA_PHONE_FORMATTED } from "../params"

import Layout from "../components/layouts/layout"
import SEO from "../components/seo"
import CallIcon from "../components/callIcon"

import StockImageSrc from "../images/stock-image-2.jpg"
import Heart from "../images/stock-image-26.jpg"
import HeartMobile from "../images/stock-image-26-mobile.jpg"
import ShieldSrc from "../images/shield.svg"

const Image = styled.img`
  width: 100%;
`

const ImageStyled = styled.img`
  width: 536px;
  @media (min-width: 768px) and (max-width: 991.98px) {
    width: 345px;
  }
  @media (max-width: 767px) {
    width: 100%;
    margin-bottom: 1.5rem;
  }
`

const Shield = styled(ShieldSrc)`
  width: 270px;
  @media (max-width: 767px) {
    width: auto;
    height: 124px;
    margin-bottom: 1.5rem;
  }
`

const ContactWrapper = styled.div`
  background-color: ${props => props.theme.colors["body-color"]};
  background-image: url("/images/contact-bg.png");
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: top center;
  min-height: 550px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  @media (min-width: 767px) and (max-width: 991.98px) {
    min-height: 450px;
  }
  @media (max-width: 767px) {
    background-image: url("/images/contact-mobile-bg.jpg");
    min-height: 650px;
  }
  @media (min-width: 1700px) {
    background-size: 1366px auto;
  }
`

const AboutUs = () => {
  const { t } = useTranslation()
  const breakpoints = useBreakpoint()

  return (
    <Layout>
      <SEO title={t("about_us")} />

      <Container className="gx-0 gx-md-3 pb-4 pb-md-6 py-md-6">
        <Row className="gx-0 gx-md-3">
          <Col
            xs={{ span: 12, order: "last" }}
            md={{ span: 6, order: "first" }}
            className="d-flex flex-column justify-content-center pe-lg-6 equalize-padding-x"
          >
            <h1>{t("about_us")}</h1>
            <h3>{t("about_us_description")}</h3>
          </Col>

          <Col
            xs={{ span: 12, order: "first" }}
            md={{ span: 6, order: "last" }}
            className="d-flex align-items-center"
          >
            <ImageStyled src={StockImageSrc} />
          </Col>
        </Row>
      </Container>

      <div className="bg-dark-blue text-white">
        <Container className="gx-0 gx-md-3">
          <Row className="gx-0 gx-md-3">
            <Col xs="12" lg className="pt-md-4 pt-lg-0">
              <Image src={breakpoints.md ? HeartMobile : Heart} />
            </Col>
            <Col
              xs="12"
              lg
              className="render-tags equalize-padding-x d-flex flex-column align-items-start justify-content-center py-4 px-md-5"
            >
              <h1>{t("mission")}</h1>
              <p className="fw-light fs-4 mb-6">{t("mission_desc")}</p>
              <h1>{t("vision")}</h1>
              <p className="fw-light fs-4">{t("vision_desc")}</p>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="bg-white">
        <Container className="d-flex flex-column flex-md-row align-items-start align-items-md-center justify-content-center py-5 px-lg-6">
          <Shield />
          <span className="fs-3 fw-light ms-md-6">
            <Trans i18nKey="about_us_promo">
              Estamos aquí para ayudarte a <b>pagar lo necesario</b> por tu
              seguro y <b>obtener la cobertura</b> que realmente necesitas.
              Trabajamos directamente con todas las compañías para asegurarnos
              de encontrar <b>la mejor opción para ti y tu familia</b>.
            </Trans>
          </span>
        </Container>
      </div>

      <ContactWrapper>
        <Container className="text-white">
          <Row>
            <Col md="7">
              <h1>{t("contact_title")}</h1>
              <h3>
                {t("contact_subtitle", { phone: APREMIA_PHONE_FORMATTED })}
              </h3>

              <a
                href={`tel:${APREMIA_PHONE}`}
                className="btn btn-primary btn-sm"
              >
                <CallIcon /> {t("call_now")}
              </a>
            </Col>
          </Row>
        </Container>
        <Container className="gx-0 gx-md-3">
          <hr className="text-secondary opacity-25 mt-6" />
        </Container>
      </ContactWrapper>
    </Layout>
  )
}

export default AboutUs

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
