import styled from "styled-components"

import CallIconSrc from "../images/icon-call.svg"

const CallIcon = styled(CallIconSrc)`
  fill: #ffffff;
  width: ${props => props.size || 24}px;
  height: ${props => props.size || 24}px;
`

export default CallIcon